.experience_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
   
}



.experience_container > div{
    /* > div means it only apply for it's child div */
    background: var(--color-bg-varient);
    padding: 2.4rem 5rem;
    border-radius: 3rem;

}

.experience_container > div:hover{

    background: transparent;
    border-color: var(--color-primary-varient);
    border: 1px solid var(--color-primary-varient);
    cursor: default;
    transition: var(--transition);

}

.experience_container >div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: darkgoldenrod;
}

.experience__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* gap: 4rem; */
    row-gap: 2rem;
    
}

.expereience__details{
    display: flex;
    gap: 1rem;
}

.expereience__details > div{
    margin-top: -0.4rem;
}

@media screen and (max-width:1024px) {

  .experience_container{
    margin-top: -2rem;
    grid-template-columns: 1fr;
  }

.experience__content{
    margin-left: 9rem;
    column-gap: 8rem;
}
    
}

@media screen and (max-width:600px) {


    section{
        width: var(--container-width-sm);
    }
    .experience_container{
        display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    
    
    }

    .experience_container > div{
        margin: 1rem;  
        padding: 0.4rem;
    }

    .experience__content{
        margin-left: 0.4rem;
        column-gap: 3rem;
    }
    
}

@media screen (max-width:290px) {


     .experience_container > div{
        
        margin: 0.6rem;  
    
    }
}