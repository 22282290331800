

.about_container{
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about_me{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary),transparent);
    display: grid;
    place-items: center;
    
}

 .about_me_image{
    border-radius: 2rem;
    overflow: hidden;
}

.about_me_image:hover{
    transform: rotate(20deg);
    transition: var(--transition);
    scroll-behavior: smooth;
}


.about_cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 3rem;
    gap: 1.5rem;
}

.about_card{
    background: var(--color-bg-varient);
    border: 1px solid transparent;
    border-radius: 1rem;
    text-align: center;
    padding: 2rem;
}

.about_card:hover{
    background: transparent;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.about_icon{

    color: var(--color-primary);
    font-size: 2rem;

}

.about_card h5{
    font-size: 1.2rem;
}

.about_card small{
    font-size: 0.8rem;
}

.about_content p{
    margin:1.4rem 0 1.5rem;
    color: antiquewhite;
}


@media screen and (max-width:1024px) {

    .section{
        height: 50vh;
    }


     .about_container{
            grid-template-columns: 1fr;
            gap: 0;
        }

    .about_me{
        width: 50%;
        margin:4rem auto;
    }
   
    
}


@media screen and (max-width:550px){

    .section{
        overflow: hidden;
    }

 .about_cards{
    display: grid;
    grid-template-columns: 1fr;
    margin: 1rem;
 }

 .about_content p{
    margin: 1rem;
 }

 .about_content .btn{
    margin-left: 1rem;
 }
}
