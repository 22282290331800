
  @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&family=Poppins:wght@300;400;500;600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&family=Poppins:wght@300;400;500;600&display=swap');

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    color: aliceblue;
}

:root{

    --color-newbg: #1b1b1b;
    --color-bg: #1f1f38;
    --color-bg-varient: #2c2c6c;
    --color-primary: #4bb5ff;
    --color-primary-varient: rgba(77, 181, 255, 0.4);
    --color-white:#fff ;
    --color-light: rgba(255,255,255,0.6);

    --transition: all 400ms ease;


    --container-width-lg:75%;
    --container-width-md: 86%;
    --contiainer-width-sm:95%;

}


html{
    scroll-behavior: smooth;
}


::-webkit-scrollbar{
    display: none;
}


body{
    font-family: 'Poppins', 'Nunito' , sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/grungoverlay.png);
    background-attachment: fixed;
  
}


/* ======================= */
/* =====Genral Styles===== */
/* ======================= */


.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,h2,h3,h4,h5{
    font-weight: 500;
}

h1{
    font-size: 2.5rem;
}

section{
    margin-top: 5rem;
    /* height: 100vh; */
}


section > h2, section>h5{
    text-align: center;
    color: var(--color-light);
}

section > h2{
    color: var(--color-primary);
    margin-top: 0.3rem;
    margin-bottom:3rem;
}

.text-light{
    color:var(--color-light);

}

a{
    color: var(--color-primary);
    transition: var(--transition) ;
}

a:hover{
    color: var(--color-white);
}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: --var(transition);
}


.btn:hover{
    background-color: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;

}


.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}


/* ==============Media query for medium devices ===========   */

@media screen and (max-width:1024px) {

    .container{
        width: var(--container-width-md);
    }
    .section{
        height: 70vh;
        margin-top: 3rem;
        
    }
}

/* ==============Media query for small devices ===========   */

@media screen and (max-width:600px) {

    .container{
        width: var(--contiainer-width-sm);
    }

    section >h2{
        margin-bottom: 2rem;
    }
    
}