.services__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-columns: 1fr 1fr; */
    /* or  grid-template-columns: repeat(2, 1fr); */
    gap: 2rem;
    height: 100vh;
    
}

 .service{
    background: var(--color-bg-varient);
    padding: 1rem 2rem;
    border-radius: 2.4rem;
    height: fit-content;
    
}

.services__container > .service:hover{
    background: transparent;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    cursor: default;
}

.service__head{
    text-align: center;
    margin-bottom: 1.4rem;
    background: var(--color-primary);
    padding: 1rem;
    border-radius: 1.4rem;
    box-shadow:0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__list {
    padding: 0.4rem;
}

.service__list li{
    display: flex;
    gap: 0.8rem;
    margin-bottom: 0.8rem;
}

.service__list p{
    font-size: 0.8rem;
}


@media screen and (max-width:1024px) {

    .services__container{
        grid-template-columns: 1fr;
       
    }
    
}

@media screen and (max-width:830px){

    section{
        margin-bottom: 7rem;
    }

    .services__container{
        margin-bottom: 5rem;
        
    }

}